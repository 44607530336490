import { Loader } from "pixi.js";
import {CDN_BASE_URL} from "../../index";

export enum MINIGAME_LETTERS_ASSETS {
    LETTERS_SHEET_DATA = "LETTERS_SHEET_DATA"
}

export const MinigameLettersLoader = (loader: Loader) => {
    loader.add(MINIGAME_LETTERS_ASSETS.LETTERS_SHEET_DATA, `${CDN_BASE_URL}/letters/letters.json`)
}
