import {Sounds} from "../classes/SoundController";

type CallbackParameters = {
    sound: boolean
    music: boolean
}

export default function soundControl(params: CallbackParameters) {
    console.log(params);
    const {sound, music} = params;
    // Howler.volume(sound ? 0.5 : 0);
    Sounds.enableSound(sound)

    Sounds.enableMusic(music)
}
