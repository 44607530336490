import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppVersion from "./components/AppVersion";
import AuthorizationWindow from "./features/Authorization";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Sounds } from "./game/classes/SoundController";

const isDev = window.origin.includes("http://localhost:");
export const CDN_BASE_URL =
  window.origin + `${isDev ? "" : "/ivar-the-boneless"}`;
document.addEventListener("gesturestart", (event) => {
  event.preventDefault();
  const body = document.body as any;
  body.style.zoom = 1;
  body.style.transform = "scale(1)";
});

document.addEventListener("gesturechange", (event) => {
  event.preventDefault();
  const body = document.body as any;
  body.style.zoom = 1;
  body.style.transform = "scale(1)";
});

document.addEventListener("gestureend", (event) => {
  event.preventDefault();
  const body = document.body as any;
  body.style.zoom = 1;
  body.style.transform = "scale(1)";
});

const handleVisibilityChange = () => {
  Sounds.muteAllSounds(document.hidden);
  Sounds.pauseBg(document.hidden);
};

document.addEventListener("visibilitychange", handleVisibilityChange, false);
window.addEventListener("focus", handleVisibilityChange, false);
window.addEventListener("blur", handleVisibilityChange, false);

const handleTouch = (event: any) => {
  if (event.touches.length > 1) event.preventDefault();
};

document.addEventListener("touchmove", handleTouch);
document.addEventListener("touchstart", handleTouch);
document.addEventListener("touchend", handleTouch);

const baseUrl =
  window.location.pathname.split("/")[1] === "ivar-the-boneless"
    ? "/ivar-the-boneless"
    : "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <AppVersion />
    <BrowserRouter basename={baseUrl}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Navigate to={"/index.html"} replace />} />
          <Route path="index.html" element={<App />} />
          <Route path="auth" element={<AuthorizationWindow />} />
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Route>
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
