import * as PIXI from "pixi.js";
import {Application} from "pixi.js";
import {assetsManager} from "./classes/assetsManager";
import {assetsLoader, preloader} from "./assetsLoader/assetsLoader";
import {slotMachine} from "./classes/slotMachine";
import {api} from "../App";
import {EventType, getEvent} from "../GameEventBus";
import {LocalStorage} from "../utils/localStorage";
import {Sounds} from './classes/SoundController';
import {WhoAmI} from '../api/types';
import {UI} from "../ui";
import clickSpin from "./ui-handlers/click-spin";
import changeBet from "./ui-handlers/change-bet";
import setAutoParams from "./ui-handlers/set-auto-params";
import setTurbo from "./ui-handlers/turbo";
import soundControl from "./ui-handlers/sound-control";
import showRules from "./ui-handlers/show-rules";
import {Howler} from "howler";
import TokenStorage from "../helpers/TokenStorage";
import logout from './ui-handlers/logout';
import {calculateDateDiff} from "./utils/calculateDateDiff";

const Window = window as any
Window.PIXI = PIXI

export let ratio = window.innerWidth / window.innerHeight;

export const currentBetIndex = JSON.parse(LocalStorage.getItem('chosenBet'))

if (!currentBetIndex) LocalStorage.setItem('chosenBet', 0)
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR

export const enum LocalKeys {
    minigame = "minigame",
    freegame = "freegame",
    gameinit = "gameinit"
}


export class Game {
    isAssetsLoaded: boolean
    isPreloaderReady: boolean
    isPreloaderInit: boolean
    isInit: boolean
    app: Application
    ref: any
    assetsManager: assetsManager
    resizeCallbacks: any[]
    slotMachine?: slotMachine
    isMiniGame: boolean
    inactiveElementsWhileSpin: any[];
    leftHandedMode: boolean
    isDebug: boolean
    initData!: WhoAmI;

    constructor() {
        this.isAssetsLoaded = false
        this.isPreloaderReady = false
        this.isPreloaderInit = false
        this.isInit = false
        this.app = new PIXI.Application({
            width: 1920,
            height: 1080,
            resolution: window.innerWidth < 900 ? 2 : 1,
            resizeTo: document.body,
            backgroundColor: 0x559fad,
            antialias: false
        })

        // @ts-ignore
        globalThis.__PIXI_APP__ = this.app;
        this.assetsManager = new assetsManager(this)
        this.resizeCallbacks = []
        this.isMiniGame = false
        this.inactiveElementsWhileSpin = []
        this.leftHandedMode = LocalStorage.getItem("isLeftHandedModeEnable");
        this.isDebug = false
    }

    async preloaderInit(ref: any) {
        const norseBold = new FontFace("Norse-Bold", "url('./assets/fonts/Norse-Bold.otf')");
        document.fonts.add(norseBold);
        await norseBold.load()

        if (this.isPreloaderInit) return
        this.app.stage.sortableChildren = true
        this.app.stage.interactiveChildren = false;
        this.isPreloaderInit = true
        this.ref = ref
        await preloader(this)
        await this.assetsManager.initPreloaderComponents()
        this.isPreloaderReady = true
        this.resize(this)
        window.addEventListener('resize', () => this.resize(this))
        ref.current.appendChild(this.app.view)
        this.app.start()
        this.init(ref);
    }

    async initUiEvents() {
        UI.events.subscribe("click:auto", () => UI.events.show("auto"))
        UI.events.subscribe("click:popup-exit-btn", () => {
            logout();
        });

        UI.events.subscribe("click:settings", () => UI.events.show("settings"))
        UI.events.subscribe("click:spin", clickSpin.bind(this))
        // UI.events.subscribe("click:faq", showPaytable.bind(this))
        UI.events.subscribe("click:question", showRules.bind(this))
        UI.events.subscribe("change:bet", changeBet.bind(this))
        UI.events.subscribe("change:auto", setAutoParams.bind(this));
        UI.events.subscribe("change:turbo", setTurbo);
        UI.events.subscribe("change:sound", soundControl);
        UI.events.subscribe("change:music", soundControl);
        UI.events.subscribe("click:auto-spin", (args: any) => {
            this.assetsManager?.autoPlayModal?.startAutoPlay(args);
        });
        UI.events.emit('win:update', {
            value: 0,
        })
        // UI.events.subscribe("change:left-hand-mode", (isHandedMode: any) => {
        //     getEvent(EventType.LEFT_HANDED_MODE_TOGGLE).send(isHandedMode.enabled);
        // });
    }

    async init(ref: any) {
        if (this.isInit) return
        const search = window.location.search
        const queryParams = new URLSearchParams(search)
        const token = queryParams.get('token')
        const backUrl = queryParams.get('backUrl')
        const language = queryParams.get('lang')

        if (!token) {
            console.log(`TOKEN NOT FOUND`)
            return
        }

        TokenStorage.setToken(token)
        UI.config.setLocale(language || 'en' as any)

        this.isInit = true
        this.ref = ref

        this.clearOldLocalFS()

        const [initData] = await Promise.all([
            await api.auth.initialize(),
            await assetsLoader(this)
        ])

        const miniLocalObject = LocalStorage.getItem(LocalKeys.minigame)
        const initLocalObject = LocalStorage.getItem(LocalKeys.gameinit)

        if (initData) {
            if (miniLocalObject && !initData.nextCommands.includes("freegame")) {
                const dateObj = new Date(miniLocalObject.saveDate)

                if (calculateDateDiff(dateObj, new Date(), "<", 30)) {// && !!initData?.results?.length) {
                    if (initLocalObject) {
                        if (!initData.results) {
                            initData.results = []
                        }
                        initData.results[0] = initLocalObject.result
                    }

                    initData.nextCommands = ["freegame", "buyextrafeature"]
                    initData.patched = true
                }
            }

            await UI.register("Ivar the Boneless", initData.wrapper_data)
            UI.config.setBalance(initData.account.value / 100)
            UI.config.setCurrency(initData.account.currency)
            UI.config.setBetSteps(initData.betsArray, initData.betsArray[1]);

            this.saveInitDataLocally(initData)
        }

        await this.assetsManager.initAllComponents()
        this.isAssetsLoaded = true
        getEvent(EventType.GAME_ASSETS_LOADED).send(true)
        this.resize(this)
        sessionStorage.setItem('purchase', 'false')
        window.addEventListener('resize', () => this.resize(this))
        window.addEventListener('orientationchange', () => this.resize(this));
        this.slotMachine = new slotMachine(this)
        await this.slotMachine.init()

        if (initData) {
            this.initData = initData;
            this.slotMachine.changeBalance(initData?.account.value / 100);
        }
        this.slotMachine.nextCommand = initData?.nextCommands[0] || ""

        Sounds.playBg();
        ref.current.appendChild(this.app.view)
        if (initData) {
            this.slotMachine.setInitData(initData);
            getEvent(EventType.INIT_DATA).send(initData)
        }
        this.app.start()
        await this.initUiEvents()

        getEvent<boolean>(EventType.MINI_GAME_IN_PROGRESS).subscribe(value => {
            this.isMiniGame = value;
        })

        getEvent<boolean>(EventType.LEFT_HANDED_MODE_TOGGLE).subscribe(value => {
            this.leftHandedMode = value;
            this.switchMode();
        })

        document.addEventListener('keydown', (e) => {
            if (e.code === 'KeyD') this.isDebug = !this.isDebug
            getEvent(EventType.IS_DEBUG).send(this.isDebug)
        })

        // this.checkSound();
    }

    clearOldLocalFS = (force?: boolean) => {
        const localToken = TokenStorage.getToken();
        const keys = [LocalKeys.minigame, LocalKeys.freegame, LocalKeys.gameinit]

        if (!localToken || force) {
            // clear all if no token
            keys.forEach(key => {
                LocalStorage.setItem(key, null)
            })
        } else {
            // clear only outdated
            keys.forEach(key => {
                const localObject = LocalStorage.getItem(key)

                if (localObject) {
                    const dateObj = new Date(localObject.saveDate)

                    if (calculateDateDiff(dateObj, new Date(), ">", 30)) {
                        LocalStorage.setItem(key, null)
                    }
                }
            })
        }

    }

    saveInitDataLocally = (initData: WhoAmI) => {
        if (!initData.patched && initData?.nextCommands?.includes("freegame") && initData?.nextCommands?.includes("buyextrafeature") && !!initData?.results?.length)
            if (initData.results) {
                const result = initData.results[0];

                // const multiplier = result.clientData.multiplier
                // const activeFeatures = result.clientData.activeFeatures
                // const featuresDetails = result.clientData.featuresDetails
                // const freeSpin = result.clientData.freeSpin


                const fsObject = {
                    // multiplier,
                    // activeFeatures,
                    // featuresDetails,
                    // freeSpin,

                    result,
                    saveDate: new Date()
                }

                LocalStorage.setItem(LocalKeys.gameinit, fsObject)

            }

    }


    checkSound = () => {
        const state = LocalStorage.getItem('isAudioEnable') === null ? true : LocalStorage.getItem('isAudioEnable')
        if (state) {
            Howler.volume(0.5);
        } else {
            Howler.volume(0);
        }
    }

    switchMode = () => {
        this.resizeCallbacks.forEach((callback) => {
            callback();
        })
    }


    resize(game: this) {
        if (!game.app) return
        const isPortrait = window.innerWidth / window.innerHeight <= 0.76

        // TODO: WHEN DESIGN WILL BE DEVELOPED USE THIS FUNCTIONS FOR ADAPTIVE
        this.resizeCallbacks.forEach((callback) => {
            callback(isPortrait);
        })
        if (isPortrait) {
            UI.config.setPlatform("mobile");
        } else {
            UI.config.setPlatform("desktop");
        }
        game.assetsManager.gameContainer.scale.x = window.innerWidth / 1920;
        game.assetsManager.gameContainer.scale.y = window.innerWidth / 1920;

        if (window.innerWidth / window.innerHeight >= 1.63) {
            game.assetsManager.gameContainer.scale.x = window.innerHeight / 1200;
            game.assetsManager.gameContainer.scale.y = window.innerHeight / 1200;
        }

        game.assetsManager.gameContainer.x = window.innerWidth / 2;
        game.assetsManager.gameContainer.y = window.innerHeight / 2 - game.assetsManager.gameContainer.height / 2 - 20;

        if (window.innerWidth / window.innerHeight <= 0.76) {
            game.assetsManager.gameContainer.y = 46;
        }
        // getEvent(EventType.OPEN_TOTAL_BET_MOBILE).send(false);

        game.app.view.style.width = window.innerWidth + 'px';
        game.app.view.style.height = window.innerHeight + 'px';

        this.app.resize()

    }

}

