import * as PIXI from 'pixi.js'
import {Game} from '../../Game'
import {buyBonusCostStyles, buyBonusTitleStyles} from '../../styles'
import CloseBtn from '../CloseBtn'
import CustomButton from '../CustomButton'
import {EventType, getEvent} from "../../../GameEventBus";
import {UI} from '../../../ui'

class BuyBonusModal {
    game: Game
    modalWrapper: PIXI.Container
    windowBackground: PIXI.Graphics
    modal: PIXI.Container
    background: PIXI.Graphics
    title: PIXI.Text
    subTitle: PIXI.Text
    closeButton?: CloseBtn
    confirmButton?: CustomButton
    isModalOpen: boolean
    isBuyBonus: boolean;
    isModalActionsEmited: boolean;

    constructor(game: Game) {
        this.game = game
        this.isModalOpen = false
        this.modal = new PIXI.Container()
        this.modalWrapper = new PIXI.Container()
        this.background = new PIXI.Graphics()
        this.windowBackground = new PIXI.Graphics()
        this.title = new PIXI.Text('Confirm purchase', buyBonusTitleStyles)
        this.subTitle = new PIXI.Text('', buyBonusCostStyles)
        this.isBuyBonus = false;
        this.isModalActionsEmited = false;
    }

    init = async () => {
        await this.setupButtons();
        this.modalWrapper.x = 0
        this.modalWrapper.y = 0
        this.modalWrapper.width = window.innerWidth
        this.modalWrapper.height = window.innerHeight
        this.modalWrapper.visible = false
        this.modalWrapper.zIndex = 10000

        this.windowBackground.beginFill(0x151515)
        this.windowBackground.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.windowBackground.endFill()
        this.windowBackground.alpha = 0.8

        this.background.beginFill(0x151515)
        this.background.drawRoundedRect(0, 0, 809, 439, 10)
        this.background.endFill()
        // this.background.x = (window.innerWidth - this.background.width) / 2
        // this.background.y = (window.innerHeight - this.background.height) / 2
        this.windowBackground.zIndex = 1
        this.modal.zIndex = 15

        this.title.x = this.background.width / 2 - this.title.width / 2
        this.title.y = 87

        this.subTitle.x = this.background.width / 2 - this.subTitle.width / 2
        this.subTitle.y = 164

        this.modal.x = (window.innerWidth - 809) / 2
        this.modal.y = (this.game.assetsManager.gameContainer.height - 439) / 2

        this.game.app.stage.addChild(this.modalWrapper)
        this.modalWrapper.addChild(this.windowBackground!)
        this.modalWrapper.addChild(this.modal!)
        this.modal.addChild(this.background!)
        this.background.addChild(this.title!)
        this.background.addChild(this.subTitle!)
        this.game.resizeCallbacks.push(this.resize)
    };

    handleClick = async () => {
        if (this.game.slotMachine) {
            if (this.game.slotMachine!.bet * 100 > this.game.slotMachine.balance) {
                let errorArray = ["Not enough funds", "Please refill your balance", '']
                getEvent(EventType.ERROR).send(errorArray);
                this.modalWrapper.visible = false;
                return
            }
            this.isBuyBonus = true;
            await this.game.slotMachine.checkIsBuyBonus(true);

            this.game.slotMachine.changeBalance(-(this.game.slotMachine!.bet * 100));

            this.game.slotMachine.nextCommand = 'buybonusspin'
            this.showModal(false)
            await this.game.slotMachine.onSpin('buybonusspin')
        } else {
            this.showModal(false)
        }
    }

    showModal = (isActive: boolean) => {
        if (isActive) {
            this.modalWrapper.visible = true
            this.game.assetsManager.gameContainer.interactiveChildren = false
            const currency = this.game.initData.account.currency ? this.game.initData.account.currency : "USD"
            this.subTitle.text = `${this.game.slotMachine!.bet * 100} ${currency}`
        } else {
            this.modalWrapper.visible = false
            this.game.assetsManager.gameContainer.interactiveChildren = true
        }
        this.resize()
    };

    setupButtons = async () => {

        this.confirmButton = new CustomButton(
            this.game,
            this.game.app,
            128,
            60,
            'BUY'
        );

        this.confirmButton.button.on('touchstart', this.openConfirmationModal)

        this.confirmButton.button.on('click', (e) => this.leftClick(e))

        await this.confirmButton.initialize()

        this.confirmButton.button.x = 809 / 2 - this.confirmButton.button.width / 2
        this.confirmButton.button.y = 250

        this.background.addChild(this.confirmButton.button!)


        this.closeButton = new CloseBtn(this.game)
        this.closeButton.button.on('touchstart', () => this.closeModal())

        this.closeButton.button.on('click', (e) => this.leftClickCloseModal(e))

        await this.closeButton!.init()

        this.closeButton.button.x = 809 - this.closeButton.button.width + 15
        this.closeButton.button.y = -15

        this.background.addChild(this.closeButton.button!)
    };

    openConfirmationModal = () => {
        this.modalWrapper.visible = false;
        UI.events.show("free-spins", {
            offers: [
                {
                    type: 'fixed',
                    amount: 8,
                    cost: (100 * Number(this.game.slotMachine?.bet)),
                    options: [],
                    expired: 1000 * 60 * 60 * 24,
                    offerID: 'qwer125',
                }
            ]
        });

        if (!this.isModalActionsEmited) {
            UI.events.emit("click:start-offer", this.handleClick);
            UI.events.emit("click:deny-offer", this.closeModal);
            this.isModalActionsEmited = true;
        }
    }

    leftClick = (e: any) => {
        if (e.data.button === 0) {
            this.openConfirmationModal();
        }
        ;
    }

    leftClickCloseModal = (e: any) => {
        if (e.data.button === 0) {
            this.closeModal()
        }
        ;
    }

    closeModal = () => {
        this.showModal(false)
    }


    changeBuyBonusModalScale(scalingX: number, scalingY: number) {
        if (!this.modal) return
        this.modal.scale.x = scalingX
        this.modal.scale.y = scalingY
    }

    changeBuyBonusModalPosition(x: number, y: number) {
        if (!this.modal) return
        this.modal.x = x
        this.modal.y = y
    }

    changeBuyBonusModalSize(x: number, y: number) {
        if (!this.modal) return
        this.modal.width = x
        this.modal.height = y
    }

    resize = () => {
        this.windowBackground.width = window.innerWidth
        this.windowBackground.height = window.innerHeight
        let scaleByWidth = 100 / 1920 * window.innerWidth / 100;
        let scaleByHeight = 100 / 1080 * window.innerHeight / 100;
        if (scaleByWidth < scaleByHeight) {
            // portrait
            this.changeBuyBonusModalScale(scaleByWidth, scaleByWidth)
            this.changeBuyBonusModalScale(0.45, 0.45);
            this.changeBuyBonusModalPosition((window.innerWidth - this.modal.width) / 2, 130)
        } else {
            // desctop
            this.changeBuyBonusModalScale(scaleByHeight, scaleByHeight)
            if (window.innerWidth <= 1080) {
                this.changeBuyBonusModalScale(0.55, 0.55);
                this.changeBuyBonusModalPosition((window.innerWidth - this.modal.width) / 2, 30)
            } else {
                this.changeBuyBonusModalScale(1, 1)
                this.changeBuyBonusModalPosition((window.innerWidth - this.modal.width) / 2, 130)
            }
        }
        this.subTitle.x = this.background.width / 2 - this.subTitle.width / 2

    }
}

export default BuyBonusModal
