import {FC, useState, useCallback} from 'react';
import styled from 'styled-components';

import advancedSettingsIcon from '../../game/assets/modal/auto-play-modal-arrow.svg';
import {Game} from "../../game/Game";
import {respondFrom, respondTo} from "../../styles/mixins";
import NumberOfSpins from "./components/NumberOfSpins";
import AutoPlayHeader from "./components/ModalHeader";
import StopAutoPlay from "./components/StopAutoPlay";
import LossLimit from "./components/LossLimit";
import SingleWinLimit from "./components/SingleWinLimit";
import { EventType, getEvent, useEventReceiver } from '../../GameEventBus';
import { SoundNames } from '../../game/constants/sounds';
import { Sounds } from '../../game/classes/SoundController';

interface IAutoPlayModal {
    game: Game;
}

const AutoPlayModal: FC<IAutoPlayModal> = ({game}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
    const [spinCount, setSpinCount] = useState<number | string>('OFF');
    const [onAnyWin, setOnAnyWin] = useState(false);
    const [isFreeSpinWon, setIsFreeSpinWon] = useState(false);
    const [lossLimit, setLossLimit] = useState<string>('NO LIMIT');
    const [singleWinLimit, setSingleWinLimit] = useState<string>('NO LIMIT');

    const changeSpinCount = useCallback(
        (count: number | string) => {
            if(!game.assetsManager.autoPlayModal) return
            Sounds.play(SoundNames.BTN_UI);
            setSpinCount(count);
            // game.assetsManager.autoPlayModal.stopSpin = true
            // game.soundController.btn_ui?.play()
        },
        [setSpinCount, game]
    );

    const stopOnAnyWin = useCallback(
        (check: boolean) => {
            if(!game.assetsManager.autoPlayModal) return
            Sounds.play(SoundNames.BTN_UI);
            game.assetsManager?.autoPlayModal?.setStopOnAnyWin(check)
            setOnAnyWin(check);
        },
        [setOnAnyWin]
    );

    const stopIsFreeSpinWon = useCallback(
        (check: boolean) => {
            if(!game.assetsManager.autoPlayModal) return
            game.assetsManager?.autoPlayModal?.setStopIsFreeSpinWon(check)
            setIsFreeSpinWon(check);
        },
        [setIsFreeSpinWon]
    );

    const changeLossLimit = useCallback(
        (limit: string) => {
            // if (!game.assetsManager.autoPlayModal) return
            // Sounds.play(SoundNames.BTN_UI);
            // game.assetsManager?.autoPlayModal?.setLossLimitValue(limit)
            // game.assetsManager.autoPlayModal.refreshLimit = true
            // setLossLimit(limit);
        },
        [setLossLimit]
    );

    const changeSingleWinLimit = useCallback(
        (limit: string) => {
            if (!game.assetsManager.autoPlayModal) return
            Sounds.play(SoundNames.BTN_UI);
            // game.assetsManager?.autoPlayModal?.setSingleWinLimitValue(limit)
            // game.assetsManager.autoPlayModal.refreshLimit = true
            // game.soundController.btn_ui?.play()
            setSingleWinLimit(limit);
        },
        [setSingleWinLimit]
    );
    
    const startAutoPlay = useCallback(() => {
        if(!game.assetsManager.autoPlayModal) return
        // if (game.slotMachine?.isSpinning) return
        // Sounds.play(SoundNames.BTN_UI);
        // getEvent(EventType.SHOW_AUTO_PLAY_MODAL).send(false)
        // game.assetsManager.autoPlayModal.refreshLimit = true
        // game.assetsManager?.autoPlayBtn?.setSpinCounter(spinCount);
        // game.assetsManager?.autoPlayModal?.startAutoPlay(spinCount);
        showModal(false);
    }, [game, spinCount]);

    const resetToDefault = () => {
        setSpinCount('OFF');
        setLossLimit('NO LIMIT');
        setSingleWinLimit('NO LIMIT');
        setOnAnyWin(false);
        setIsFreeSpinWon(false);
    }

    const showModal = useCallback((isOpen: boolean) => {
        // if(!isOpen) Sounds.play(SoundNames.BTN_UI);
        // const startSpinCount = game.slotMachine?.requestParams?.startSpinCount ? game.slotMachine?.requestParams?.startSpinCount : 'OFF';
        // const startLossLimit = game.slotMachine?.requestParams?.lossLimit !== 0 ? `${game.slotMachine?.requestParams?.lossLimit}x` : 'NO LIMIT';
        // const startSingleWinLimit = game.slotMachine?.requestParams?.singleWin !== 0 ? `${game.slotMachine?.requestParams?.singleWin}x` : 'NO LIMIT';
        // const startOnAnyWin = game.slotMachine?.requestParams?.anyWin ? game.slotMachine?.requestParams?.anyWin : false; 
        // const startIsFreeSpinWon = game.slotMachine?.requestParams?.freeSpins ? game.slotMachine?.requestParams?.freeSpins : false;
        
        setModalOpen(isOpen);
        // setSpinCount(startSpinCount);
        // setLossLimit(startLossLimit);
        // setSingleWinLimit(startSingleWinLimit);
        // setOnAnyWin(startOnAnyWin);
        // setIsFreeSpinWon(startIsFreeSpinWon);

        if (game.assetsManager && game.assetsManager.autoPlayBtn) {
            game.assetsManager.autoPlayBtn.isModalActive = isOpen;
        }
        if (game.assetsManager.autoPlayModal?.isAutoPlayRunning === false && game.assetsManager.autoPlayModal?.isPaused === false) {
            resetToDefault();
        };
    }, [game.slotMachine, game.assetsManager]);

    const toggleAdvancedSettings = useCallback((status: boolean) => {
        Sounds.play(SoundNames.BTN_UI);
        setAdvancedSettingsOpen(status)
    }, [setAdvancedSettingsOpen])


    useEventReceiver<boolean>(EventType.SHOW_AUTO_PLAY_MODAL, value => {
        showModal(value);
    })

    const closeModal = useCallback(() => {
        getEvent(EventType.SHOW_AUTO_PLAY_MODAL).send(false)
        if (game.assetsManager.autoPlayModal?.isAutoPlayRunning === false && game.assetsManager.autoPlayModal?.isPaused === false) {
            resetToDefault();
        };
        if (game.assetsManager.autoPlayModal!.pausedCounter > 0) {
            game.assetsManager.autoPlayModal!.setCounter(game.assetsManager.autoPlayModal!.pausedCounter)
            if(game.assetsManager.autoPlayBtn?.autoSpinCount) game.assetsManager?.autoPlayBtn?.setSpinCounter(game.assetsManager.autoPlayBtn.autoSpinCount);
        }
        showModal(false)
    }, [showModal, game])

    return (
        <Wrapper style={game.assetsManager.autoPlayBtn?.isModalActive ? {display: 'flex'} : {display: 'none'}}>
            <Container>
                <div>
                    <AutoPlayHeader
                        spinCount={spinCount}
                        closeModal={closeModal}
                        startAutoPlay={startAutoPlay}
                        game={game}
                    />

                    <NumberOfSpins
                        spinCount={spinCount}
                        changeSpinCount={changeSpinCount}
                    />
                    <FooterWrapper>
                        <FooterTitle>ADVANCED SETTINGS</FooterTitle>
                        <FooterButton
                            style={isAdvancedSettingsOpen ? {} : {transform: 'rotate(0)'}}
                            onClick={() => toggleAdvancedSettings(!isAdvancedSettingsOpen)}
                        >
                            <img src={advancedSettingsIcon} alt='Arrow'/>
                        </FooterButton>
                    </FooterWrapper>
                </div>
                <AdvancedSettingsWrapper
                    style={
                        isAdvancedSettingsOpen ? {display: 'flex'} : {display: 'none'}
                    }
                >
                    <StopAutoPlay
                        onAnyWin={onAnyWin}
                        stopOnAnyWin={stopOnAnyWin}
                        isFreeSpinWon={isFreeSpinWon}
                        stopIsFreeSpinWon={stopIsFreeSpinWon}
                    />
                    <LossLimit lossLimit={lossLimit} changeLossLimit={changeLossLimit}/>
                    <SingleWinLimit
                        singleWinLimit={singleWinLimit}
                        changeSingleWinLimit={changeSingleWinLimit}
                    />
                </AdvancedSettingsWrapper>
            </Container>
        </Wrapper>
    );
};

export default AutoPlayModal;

const Wrapper = styled.div`
  padding: 38px 6px 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(21, 21, 21, 0.8);
  left: 0;
  top: 0;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${respondFrom.sm`
		align-items: flex-end;
		justify-content: center;
	`}
  ${respondTo.lg`
		align-items: center;
		justify-content: center;
	`}
`;

const Container = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #151515;
  padding: 8px 9px 10px;
  border: 1px solid #2a2c2b;
  border-radius: 15px;
  width: 304px;
  max-height: 100%;
  ${respondFrom.sm`
		max-width: 360px;
		width: 100%;
	`}
`;

const FooterTitle = styled.div`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #7c8584;
  flex: auto;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  position: relative;
  margin-top: 16px;
`;

const FooterButton = styled.div`
  transform: rotate(180deg);
  transition: all ease 0.2s;
  position: absolute;
  right: 7px;
  cursor: pointer;
`;

const AdvancedSettingsWrapper = styled.div`
  flex-direction: column;
`;
