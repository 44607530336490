import React, { useEffect, useRef, useState } from 'react';
import { Game } from "./game/Game";
import Api from "./api";
import styled from "styled-components";
import AutoPlayModal from "./features/AutoPlayModal";
import { EventType, useEventReceiver } from "./GameEventBus";
import ConfirmationModal from './features/Modals/ConfirmationModal/ConfirmationModal';

import ErrorModal from './features/Modals/ErrorModal';
import MobileTotalBetModal from './features/Modals/TotalBetModalMobile';
import TokenAuthError from './features/Authorization/TokenAuthError';
import DebugPlayModal from "./features/DebugModal/DebugModal";
const game = new Game()

const rubik = new FontFace("Rubik", "url(https://fonts.gstatic.com/s/rubik/v23/iJWKBXyIfDnIV7nBrXw.woff2)", { weight: '300 600' });
document.fonts.add(rubik);
rubik.load()

export const api = new Api()

function App() {
    const [isAssetsLoaded, setIsAssetsLoaded] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        if(!ref.current) return
        game.preloaderInit(ref);

        return () => {
            game?.app?.stop();
        };
    }, [ref]);


    useEventReceiver<boolean>(EventType.GAME_ASSETS_LOADED, value => {
        setIsAssetsLoaded(value);
    });

    return (
        <Root>
            {/*<Header game={game}/>*/}
            {/*<NavBar game={game} />*/}
            <div style={{display: 'flex', justifyContent: 'center'}} ref={ref}>
            </div>
            {isAssetsLoaded &&
                    <>
                        <ErrorModal game={game}/>
                        <MobileTotalBetModal game={game}/>
                        <AutoPlayModal game={game}/>

                    </>
            }
            {/*MODALS*/}
            <>
                <ConfirmationModal isAssetsLoaded={isAssetsLoaded} game={game}/>
                <DebugPlayModal />
                <TokenAuthError />
            </>
        </Root>
    );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`

export default App;
