import { WrapperProxy } from "@playmer/ui-wrapper";

const subscribeMethods = {
	"click:spin": "onSpinClick",
	"click:logo": "onLogoClick",
	"click:title": "onTitleClick",
	"click:auto": "onAutoClick",
	"click:settings": "onSettingsClick",
	"click:music": "onMusicClick",
	"click:hand": "onHandClick",
	"click:visual": "onVisualClick",
	"click:close": "onCloseClick",
	"click:arrow-left": "onArrowLeftClick",
	"click:arrow-right": "onArrowRightClick",
	"click:exit": "onExitClick",
	"click:popup-exit-btn": "onPopupExitBtnClick",
	"click:popup-close-btn": "onPopupCloseBtnClick",
	"click:burger": "onBurgerClick",
	"click:question": "onQuestionClick",
	"click:win": "onWinClick",
	"click:balance": "onBalanceClick",
	"click:sound": "onSoundClick",
	"click:help": "onHelpClick",
	"click:lobby": "onLobbyClick",
	"click:start-offer": "onStartOfferClick",
	"click:deny-offer": "onDenyOfferClick",
	"click:auto-spin": "onAutoSpinClick",
	"click:take-win": "onTakeWinClick",
	"change:bet": "onBetChange",
	"change:sound": "onSoundChange",
	"change:music": "onMusicChange",
	"change:auto": "onAutoChange",
	"change:turbo": "onTurboChange",
	"change:intro": "onIntroChange",
	"change:reality-check": "onRealityCheckChange",
	"change:left-hand-mode": "onLeftHandModeChange",
	"change:reality-check-popup-display": "onRealityCheckPopupDisplay",
	"change:reality-check-popup-hide": "onRealityCheckPopupHide",
	"wrapper-event-listener": "addWrapperEventListener",
} as const;

const unsubscribeMethods = {
	"click:spin": "offSpinClick",
	"click:logo": "offLogoClick",
	"click:title": "offTitleClick",
	"click:auto": "offAutoClick",
	"click:settings": "offSettingsClick",
	"click:music": "offMusicClick",
	"click:hand": "offHandClick",
	"click:visual": "offVisualClick",
	"click:close": "offCloseClick",
	"click:arrow-left": "offArrowLeftClick",
	"click:arrow-right": "offArrowRightClick",
	"click:exit": "offExitClick",
	"click:popup-exit-btn": "offPopupExitBtnClick",
	"click:popup-close-btn": "offPopupCloseBtnClick",
	"click:burger": "offBurgerClick",
	"click:question": "offQuestionClick",
	"click:win": "offWinClick",
	"click:balance": "offBalanceClick",
	"click:sound": "offSoundClick",
	"click:help": "offHelpClick",
	"click:lobby": "offLobbyClick",
	"click:start-offer": "offStartOfferClick",
	"click:deny-offer": "offDenyOfferClick",
	"click:auto-spin": "offAutoSpinClick",
	"click:take-win": "offTakeWinClick",
	"change:bet": "offBetChange",
	"change:sound": "offSoundChange",
	"change:music": "offMusicChange",
	"change:auto": "offAutoChange",
	"change:turbo": "offTurboChange",
	"change:intro": "offIntroChange",
	"change:reality-check": "offRealityCheckChange",
	"change:left-hand-mode": "offLeftHandModeChange",
	"change:reality-check-popup-display": "offRealityCheckPopupDisplay",
	"change:reality-check-popup-hide": "offRealityCheckPopupHide",
	"wrapper-event-listener": "removeWrapperEventListener",
} as const;

const showMethods = {
	settings: "showSettings",
	error: "showError",
	auto: "showAuto",
	exit: "showExit",
	bet: "showBet",
	"reality-check": "showRealityCheck",
	"free-spins": "showFreeSpins",
	"free-spins-win": "showFreeSpinsWin",
	"show-spin-btn": "showSpinButton",
	"hide-spin-btn": "hideSpinButton",
	"show-auto-spin-btn": "showAutoSpinButton",
	"hide-auto-spin-btn": "hideAutoSpinButton",
} as const;

type ShowMethods = {
	readonly settings: WrapperProxy["showSettings"];
	readonly error: WrapperProxy["showError"];
	readonly auto: WrapperProxy["showAuto"];
	readonly exit: WrapperProxy["showExit"];
	readonly bet: WrapperProxy["showBet"];
	readonly "reality-check": WrapperProxy["showRealityCheck"];
	readonly "free-spins": WrapperProxy["showFreeSpins"];
	readonly "free-spins-win": WrapperProxy["showFreeSpinsWin"];
	readonly "show-spin-btn": WrapperProxy["showSpinButton"];
	readonly "hide-spin-btn": WrapperProxy["hideSpinButton"];
	readonly "show-auto-spin-btn": WrapperProxy["showAutoSpinButton"];
	readonly "hide-auto-spin-btn": WrapperProxy["hideAutoSpinButton"];
};

const emitMethods = {
	"spin:start": "emitSpinStart",
	"spin:stop": "emitSpinStop",
	"free-spin:start": "emitFreeSpinStart",
	"free-spin:update": "emitFreeSpinsUpdate",
	"auto:start": "emitAutoStart",
	"auto:stop": "emitAutoStop",
	"win:update": "emitWinUpdate",
	"balance:update": "emitBalanceUpdate",
	"reality-check:update": "emitRealityCheckStatsUpdate",
	"toggle-intro": "emitToggleIntro",
	"ante-bet:update": "emitAnteBetUpdate",
	"bet-value-and-steps:update": "emitBetValueAndStepsUpdate",
	"free-spins-win-value:update": "emitFreeSpinsWinValueUpdate",
	"click:start-offer": "onStartOfferClick",
	"click:deny-offer": "onDenyOfferClick",
} as const

type EmitMethods = {
	readonly "spin:start": WrapperProxy["emitSpinStart"];
	readonly "spin:stop": WrapperProxy["emitSpinStop"];
	readonly "free-spin:start": WrapperProxy["emitFreeSpinStart"];
	readonly "free-spin:update": WrapperProxy["emitFreeSpinsUpdate"];
	readonly "auto:start": WrapperProxy["emitAutoStart"];
	readonly "auto:stop": WrapperProxy["emitAutoStop"];
	readonly "win:update": WrapperProxy["emitWinUpdate"];
	readonly "balance:update": WrapperProxy["emitBalanceUpdate"];
	readonly "reality-check:update": WrapperProxy["emitRealityCheckStatsUpdate"];
	readonly "toggle-intro": WrapperProxy["emitToggleIntro"];
	readonly "ante-bet:update": WrapperProxy["emitAnteBetUpdate"];
	readonly "bet-value-and-steps:update": WrapperProxy["emitBetValueAndStepsUpdate"];
	readonly "free-spins-win-value:update": WrapperProxy["emitFreeSpinsWinValueUpdate"];
	readonly "click:start-offer": WrapperProxy["onStartOfferClick"];
	readonly "click:deny-offer": WrapperProxy["onDenyOfferClick"];
}

export default class EventResolver {
	constructor(readonly _wrapper: WrapperProxy) {
		this._wrapper = _wrapper;
	}

	/**
	 * Подписка на события UI
	 * @param event Название события
	 * @param callback Функция обратного вызова
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.events.subscribe("click:spin", () => {
	 *    console.log("Spin button clicked");
	 * });
	 * ```
	 */
	subscribe(event: keyof typeof subscribeMethods, callback: Function) {
		const method = subscribeMethods[event] as any;
		const handler = (this._wrapper as any)[method].bind(this._wrapper) as any;

		if (method && handler) {
			handler(callback);
		}
	}

	/**
	 * Отписка от событий UI
	 * @param event Название события
	 * @param callback Функция обратного вызова
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.events.unsubscribe("click:spin", () => {
	 *   console.log("Spin button clicked");
	 * });
	 * ```
	 */
	unsubscribe(event: keyof typeof unsubscribeMethods, callback: Function) {
		const method = unsubscribeMethods[event] as any;
		const handler = (this._wrapper as any)[method].bind(this._wrapper);

		if (method && handler) {
			handler(callback);
		}
	}

	/**
	 * Подписка на кастомные события UI
	 * @param event Название события
	 * @param callback Функция обратного вызова
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.events.addListener("custom:event", () => {
	 *   console.log("Custom event");
	 * });
	 * ```
	 */
	addListener(event: string, callback: Function) {
		this._wrapper.addWrapperEventListener.bind(this._wrapper)(event, callback);
	}

	/**
	 * Отписка от кастомных событий UI
	 * @param event Название события
	 * @param callback Функция обратного вызова
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.events.removeListener("custom:event", () => {
	 *  console.log("Custom event");
	 * });
	 */
	removeListener(event: string, callback: Function) {
		this._wrapper.removeWrapperEventListener.bind(this._wrapper)(event, callback);
	}

	/**
	 * Показать модальное окно
	 * @param event Название окна
	 * @param data Данные для передачи в окно
	 * 
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 * 
	 * UI.events.show("settings")
	 * ```
	 */
	show<T extends keyof typeof showMethods>(event: T, ...data: Parameters<ShowMethods[T]>) {
		const method = showMethods[event];
		const handler = this._wrapper[method].bind(this._wrapper) as any;

		if (!method || !handler) return;

		handler(...data);
	}

	/**
	 * Вызвать событие
	 * @param event Название события
	 * @param data Данные для передачи в событие
	 * 
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 * 
	 * UI.events.emit("spin:start")
	 * ```
	 */
	emit<T extends keyof typeof emitMethods>(event: T, ...data: Parameters<EmitMethods[T]>) {
		const method = emitMethods[event];
		const handler = this._wrapper[method].bind(this._wrapper) as any;

		if (!method || !handler) return;

		handler(...data);
	}
}
