import {GameButtons} from '../../ui/constants';

export interface ApiResponse<T> {
    // response: {
    results: T[];
    nextCommands: string[];
    // }
}

export type SymbolName =
    | 'L1'
    | 'L2'
    | 'L3'
    | 'L4'
    | 'H1'
    | 'H2'
    | 'H3'
    | 'H4'
    | 'WILD'
    | 'SCATTER'
    | 'CHEST';

export enum SymbolNames {
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    L4 = 'L4',
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    WILD = 'WILD',
    SCATTER = 'SCATTER',
    CHEST = 'CHEST',
}

export enum SymbolPriority {
    L1 = 7,
    L2 = 8,
    L3 = 9,
    L4 = 10,
    H1 = 3,
    H2 = 4,
    H3 = 5,
    H4 = 6,
    WILD = 2,
    SCATTER = 1,
    CHEST = 1,
}

export interface Cords {
    x: number;
    y: number;
}

export interface WinEvent {
    coords: Cords[];
    id: number;
    symbol: SymbolName;
    type: EventType;
    win: number;
    StickyWild?: StickyWildEvent[];
}

export interface StickyWildEvent {
    coords: Cords[];
    id: number;
    symbol: SymbolName;
}

export type EventType =
    | 'MainGame'
    | 'FreeGame'
    | 'MiniGame'
    | 'ScatterWin'
    | 'ChestDropped';

export interface Reel {
    board: SymbolName[][];
    eventsLeft: WinEvent[];
    type: EventType;
    symbolEvents: SymbolEvents[];
}

export interface SymbolEvents {
    x: number;
    y: number;
    symbolToWild: SymbolName;
    symbolToWildId: number;
}

export interface Account {
    bonusValue: number;
    currency: string;
    precision: number;
    value: number;
}

export interface FreeSpin {
    added: number;
    count: number;
    left: number;
}

export interface FeatureDetailed {
    name: string;
    value: any;
}

export interface PlayResponse {
    coinWin: number;
    cashWin: number;
    isAutoPlayStopped: boolean;
    sumOfFreeWin: number;
    winDescription?: string;
    clientData: {
        cashWin: number;
        coinWin: number;
        account: Account;
        multiplier: number;
        featuresDetails: FeatureDetailed[];
        reels: Reel;
        freeSpin: FreeSpin;
        buyExtraFeatureCost?: any;
    };
}

export interface DebugReques {
    bet: number;
    command: string;
    limitReq?: DebugLimitReq;
    testReels?: {
        screen: Array<Array<any>>;
    };
    feature?: number;
}

export interface DebugLimitReq {
    anyWin: boolean;
    freeSpins: boolean;
    singleWin: null | number;
    lossLimit: null | number;
    refreshLoss: boolean;
}

export interface WhoAmI {
    ActiveFeatures: any;
    FeatureSymbols: any;
    FreeSpins_number: number;
    Multiplier: number;
    N_scatters: number;
    account: Account;
    betsArray: number[];
    chest4Cost: number[];
    chest5Cost: number[];
    lastMainGameWin: number;
    minigamePlayed: false;
    nextCommands: string[];
    RTP: number;
    chest4RTP: number;
    chest5RTP: number;
    maxWinCap: number;
    results?: ClientData[];
    game_settings: GameSettings[];
    wrapper_data: WrapperData;
    patched?: boolean
}

export interface WrapperData {
    ANTE_BET: string,
    AUTO_PLAY: string,
    BUY_BONUS: string,
    CURRENCY_CODE: string,
    ELAPSED_PROGRESS: string,
    FORCE_STOP_SPIN: string,
    JURISDICTION_CODE: string,
    REALITY_CHECK: string,
    RTP: string,
    SPIN_COOL_DOWN: string,
    TURBO_SPIN: string,
}

export interface ClientData {
    clientData: {
        cashWin: number
        coinWin: number
        account: Account
        multiplier: number
        featuresDetails: FeatureDetailed[]
        reels: Reel
        freeSpin: FreeSpin
        buyExtraFeatureCost?: any
        activeFeatures: any[]
    }
}

export interface GameSettings {
    name: keyof typeof GameButtons;
    value: string;
}
