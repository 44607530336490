import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import {Texture} from 'pixi.js';
import {Game} from '../../Game';
import {BUTTONS_ASSETS} from "../../assetsLoader/ButtonsLoader";
import {Sounds} from '../../classes/SoundController';
import {SoundNames} from '../../constants/sounds';

class BuyBonusButton {
    game: Game;
    btn: PIXI.Container;
    btnDefault: PIXI.Sprite;
    btnHover: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;

    constructor(game: Game) {
        this.game = game;
        this.btn = new PIXI.Container();
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.btnDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_BTN_DEFAULT));
        this.btnHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BUY_BONUS_BTN_HOVER));
    }

    init = () => {
        this.setupBtnContainer();
        this.setupBtnDefault();
        this.setupBtnHover();

        this.game.assetsManager.gameContainer.addChild(this.btn);
        this.game.resizeCallbacks.push(this.resize);
        this.game.inactiveElementsWhileSpin.push(this.btn)
    }

    setupBtnContainer = () => {
        this.btn.name = "BUY_BOUNS_BTN";
        this.btn.width = 198;
        this.btn.height = 198;
        this.btn.x = 101;
        this.btn.y = 689;
        this.btn.interactive = true;
        this.btn.cursor = 'pointer';
        this.btn
            .on('pointerup', this.DoPointerUp)
            .on('pointerupoutside', this.DoPointerUpOutside)
            .on('touchstart', this.DoPointerDown)
            .on('click', this.DoPointerDown)
            .on('pointerover', this.DoPointerOver)
            .on('pointerout', this.DoPointerOut)
    }


    setupBtnDefault = () => {
        this.btn.addChild(this.btnDefault);
    }

    setupBtnHover = () => {
        this.btnHover.alpha = 0;
        this.btn.addChild(this.btnHover);
    }

    DoPointerUp = () => {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnHover,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                {alpha: 1},
                {alpha: 0, repeat: 0}
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
        }
        this.game.assetsManager.buyBonusModal?.showModal(true)
        this.pointerIsDown = false;

    }

    DoPointerUpOutside = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    DoPointerDown = () => {
        Sounds.play(SoundNames.BTN_UI);
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );

        this.pointerIsDown = true;

    }

    DoPointerOver = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsOver = true;
    }

    DoPointerOut = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            if (this.game.leftHandedMode) {
                this.btn.scale.set(1.099);
                this.btn.y = 1749;
                this.btn.x = 1590;
            } else {
                this.btn.scale.set(1.099);
                this.btn.y = 1749;
                this.btn.x = 90;
            }

        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.btn.x = 1635;
                    this.btn.y = 565;
                    this.btn.scale.set(0.92);

                } else {
                    this.btn.x = 90;
                    this.btn.y = 565;
                    this.btn.scale.set(0.92);
                }

            } else {
                this.btn.scale.set(1)
                this.btn.width = 198;
                this.btn.height = 198;
                this.btn.x = 101;
                this.btn.y = 689;
            }
        }

    }

}

export default BuyBonusButton;
