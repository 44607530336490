import * as PIXI from "pixi.js"
import {Texture} from "pixi.js"
import {MODAL_ASSETS} from "../../assetsLoader/modalLoader"
import {Game} from "../../Game";
import {Timer} from "../../../utils/Timer";
import {Spine} from "pixi-spine";
import {Sounds} from '../../classes/SoundController';
import {SoundNames} from '../../constants/sounds';

class WinModal {
    game: Game;
    overlay: PIXI.Graphics;
    container: PIXI.Container;
    wins: Map<string, Spine>;
    modalContainer: PIXI.Container;
    timers: Timer[];
    numbers: PIXI.Container;
    duration!: number;
    bigWin: Spine;
    megaWin: Spine;
    superWin: Spine;
    coinShower: Spine;
    modalNumbersTextures: any;
    modalNumbers: Map<string, Texture>;
    valueAnimIsFinished: boolean;
    isSetWinAmountStated: boolean;
    isModalOpen: boolean;
    animations: string[];
    winType: string;
    winAmount!: number;

    constructor(game: Game) {
        this.game = game;
        this.overlay = new PIXI.Graphics();
        this.container = new PIXI.Container();
        this.wins = new Map();
        this.modalContainer = new PIXI.Container();
        this.timers = [];
        this.numbers = new PIXI.Container();
        const bigWinData = this.game.app.loader.resources[MODAL_ASSETS.BIG_WIN].spineData!
        const megaWinData = this.game.app.loader.resources[MODAL_ASSETS.MEGA_WIN].spineData!
        const superWinData = this.game.app.loader.resources[MODAL_ASSETS.SUPER_WIN].spineData!
        const coinShowerData = this.game.app.loader.resources[MODAL_ASSETS.COIN_SHOWER].spineData!
        this.bigWin = new Spine(bigWinData);
        this.megaWin = new Spine(megaWinData);
        this.superWin = new Spine(superWinData);
        this.coinShower = new Spine(coinShowerData);
        this.modalNumbersTextures = this.game.app.loader.resources.BW_SHEET.spritesheet?.textures;
        this.modalNumbers = new Map();
        this.animations = ["start", "idle", "finish"];
        this.valueAnimIsFinished = false;
        this.isSetWinAmountStated = false;
        this.isModalOpen = false;
        this.winType = "";
    }

    init = async () => {
        this.setupOverlay();
        this.setupContainer();
        this.setupModalContainer();
        this.setupModalText();
        this.setupNumbers();

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);
        this.game.resizeCallbacks.push(this.resize)
    }

    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.65;

        this.overlay.interactive = true;
        this.overlay.cursor = 'pointer';

        this.overlay.on('click', () => this.closeModal());
        this.overlay.on('touchstart', () => this.closeModal());

        this.overlay.visible = false;
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(950, 540);
        this.container.name = "WIN_MODALS";

        this.container.interactive = true;
        this.container.cursor = 'pointer';

        this.container.on('click', () => this.closeModal());
        this.container.on('touchstart', () => this.closeModal());
        this.container.visible = false;
    }

    setupModalContainer = () => {
        this.modalContainer.height = 755;
        this.modalContainer.width = 756;
        this.modalContainer.y = 350;
        this.modalContainer.x = 960;
        this.modalContainer.name = "WIN_MODALS_CONTAINER";
        this.modalContainer.sortableChildren = true;
        this.numbers.name = "WIN_NUMBERS"
        this.coinShower.zIndex = -1;
        this.modalContainer.addChild(this.numbers);
        this.container.addChild(this.modalContainer);
    }

    setupModalText = () => {
        this.bigWin.name = "BIG_WIN"
        this.megaWin.name = "MEGA_WIN"
        this.superWin.name = "SUPER_WIN";
        this.superWin.y = -30;

        this.wins.set("Big Win", this.bigWin);
        this.wins.set("Mega Win", this.megaWin);
        this.wins.set("Super Win", this.superWin);

        this.wins.forEach((win) => {
            win.visible = false;
            this.modalContainer.addChild(win);
        });
        this.modalContainer.addChild(this.coinShower);
    }

    setupNumbers = () => {
        this.modalNumbers.set("1", this.modalNumbersTextures["1_bw.png"])
        this.modalNumbers.set("2", this.modalNumbersTextures["2_bw.png"])
        this.modalNumbers.set("3", this.modalNumbersTextures["3_bw.png"])
        this.modalNumbers.set("4", this.modalNumbersTextures["4_bw.png"])
        this.modalNumbers.set("5", this.modalNumbersTextures["5_bw.png"])
        this.modalNumbers.set("6", this.modalNumbersTextures["6_bw.png"])
        this.modalNumbers.set("7", this.modalNumbersTextures["7_bw.png"])
        this.modalNumbers.set("8", this.modalNumbersTextures["8_bw.png"])
        this.modalNumbers.set("9", this.modalNumbersTextures["9_bw.png"])
        this.modalNumbers.set("0", this.modalNumbersTextures["0_bw.png"])
        this.modalNumbers.set(".", this.modalNumbersTextures["dot_bw.png"])
        this.modalNumbers.set(",", this.modalNumbersTextures["comma_bw.png"])
    }

    setWin = (digits: string, letterSpacing: number = 20) => {
        let xPos = new PIXI.Container();
        xPos.x = 0;
        xPos.y = 0;
        this.numbers.removeChildren()
        for (let i = 0; i < digits.length; i++) {
            let letter = new PIXI.Sprite(this.modalNumbers.get(digits[i].toLowerCase()))
            letter.position.copyFrom(xPos);
            letter.x += xPos.width + letterSpacing;
            letter.y = 0;
            this.numbers.addChild(letter)
            xPos = letter;
        }

        this.switchPos(this.numbers, digits.length)
        window.addEventListener('resize', () => this.switchPos(this.numbers, digits.length))

        xPos = new PIXI.Container();
        xPos.destroy();
    }

    stopAnimation = () => {
        this.wins.forEach((win, key) => {
            if (this.winType === key) win.state.setAnimation(0, this.animations[1], true);
        });
        this.overlay.interactive = false;
        this.container.interactive = false;
        this.valueAnimIsFinished = true;
        this.duration = 0;
        this.clearTimers();
        this.wins.forEach((win, key) => {
            this.winType === key ? (win.visible = true) : (win.visible = false);
        });
    };

    closeModal = async () => {
        Sounds.stop(SoundNames.BIG_WIN);
        Sounds.play(SoundNames.BIG_WIN_END);
        this.stopAnimation()
        this.wins.forEach((win, key) => {
            if (this.winType === key) win.state.setAnimation(0, this.animations[2], false);
        });
        let timer = new Timer(() => {
            this.wins.forEach((win) => {
                win.state.clearListeners();
                win.visible = false;
            });
            this.isModalOpen = false;
            this.overlay.visible = false;
            this.container.visible = false;
            this.game.assetsManager.gameContainer.interactiveChildren = true;
            this.valueAnimIsFinished = false;
            this.clearNumbers();
            Sounds.stop(SoundNames.BIG_WIN_END);
            Sounds.playBg();
            this.coinShower.state.setAnimation(0, 'Big-Win_coin_shower', false)
            this.coinShower.state.setAnimation(0, 'Mega-Win_coin_shower', false)
            this.coinShower.state.setAnimation(0, 'Super-Win_coin_shower', false)
        }, 1000)
        timer.initialize();
    }

    switchPos = (numbers: PIXI.Container, lineLength: number) => {
        switch (lineLength) {
            case 3:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -135;
                } else {
                    numbers.x = -190;
                }

                break;
            case 4:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -165;
                } else {
                    numbers.x = -230;
                }

                break;
            case 5:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -195;
                } else {
                    numbers.x = -295;
                }

                break;
            case 6:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -245;
                } else {
                    numbers.x = -350;
                }

                break;
            case 7:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -290;
                } else {
                    numbers.x = -415;
                }

                break;
            case 8:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -330;
                } else {
                    numbers.x = -475;
                }

                break;
            case 9:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -365;
                } else {
                    numbers.x = -515;
                }

                break;
            case 10:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -425;
                } else {
                    numbers.x = -590;
                }

                break;
            case 11:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -455;
                } else {
                    numbers.x = -650;
                }
                break;
            default:
                numbers.x = 0;
                break;
        }
    }

    setWinAmount = (startValue: number, value: number) => {
        let animatedValue = startValue;
        this.isSetWinAmountStated = true;
        const animValue = (start: number, end: number, duration: number) => {
            let startTimestamp: any = null;
            const step = (timestamp: any) => {
                if (!startTimestamp) startTimestamp = timestamp;
                let progress = Math.min((timestamp - startTimestamp) / duration, 1)
                animatedValue = Number((progress * (end - start) + start).toFixed(2))
                if ((progress < 1) && !this.valueAnimIsFinished) {
                    window.requestAnimationFrame(step)
                } else {
                    animatedValue = this.winAmount;
                }
                this.setWin(`${Number(animatedValue).toFixed(2)}`);

            }
            window.requestAnimationFrame(step);
        }
        animValue(startValue, value, this.duration);
    }

    openModal = (winType: string, amount: number) => {
        this.winType = winType;
        this.duration = winType === "Super Win" ? 4000 : winType === "Mega Win" ? 6000 : 10000;
        this.winAmount = amount;
        Sounds.pauseBg(true);
        Sounds.play(SoundNames.BIG_WIN);
        this.isModalOpen = true;
        this.setWinType(winType, amount)
        this.game.assetsManager.gameContainer.interactiveChildren = false;
        this.overlay.interactive = true;
        this.container.interactive = true;
        this.overlay.visible = true;
        this.container.visible = true;
        this.clearNumbers();
    }

    setWinType = async (winType: string, amount: number) => {
        if (winType === "Big Win") {
            await this.setupWinAnimation("Big Win", 0, amount);
            this.closeModalTimer();
        }

        if (winType === "Mega Win") {
            await this.setupWinAnimation("Big Win", 0, this.game.slotMachine?.bet! * 45);
            await this.setupWinAnimation("Mega Win", this.game.slotMachine?.bet! * 45, amount);
            this.closeModalTimer();
        }

        if (winType === "Super Win") {
            await this.setupWinAnimation("Big Win", 0, this.game.slotMachine?.bet! * 45);
            await this.setupWinAnimation("Mega Win", this.game.slotMachine?.bet! * 45, this.game.slotMachine?.bet! * 70);
            await this.setupWinAnimation("Super Win", this.game.slotMachine?.bet! * 70, amount);
            this.closeModalTimer();
        }
    };

    closeModalTimer = () => {
        let timer = new Timer(() => {
            let timer = new Timer(() => {
                this.closeModal();
            }, 1000)
            timer.initialize();

        }, 1)
        timer.initialize();
    }

    setupWinAnimation = async (winType: string, startValue: number, value: number) => {
        return new Promise((resolve) => {
            this.setWinAmount(startValue, value)
            if (this.valueAnimIsFinished) return resolve(true);
            this.winVisibility(winType);
            const winSpine = this.wins.get(winType) as Spine;
            winSpine.state.setAnimation(0, 'start', false);
            const coinCurrentAnim = winType === 'Big Win' ? 'Big-Win_coin_shower' : winType === 'Super Win' ? 'Super-Win_coin_shower' : 'Mega-Win_coin_shower'
            this.coinShower.state.setAnimation(0, coinCurrentAnim, true)

            const idleAnimTimer = new Timer(() => {
                winSpine.state.setAnimation(0, this.animations[1], true);
            }, 600);
            idleAnimTimer.initialize();

            const endAnimTimer = new Timer(() => {
                if (winType !== this.winType) winSpine.state.setAnimation(0, this.animations[2], false);
            }, this.duration - 500);
            endAnimTimer.initialize();

            const resolveTimer = new Timer(() => {
                return resolve(true);
            }, this.duration);
            resolveTimer.initialize();

            this.timers.push(idleAnimTimer);
            this.timers.push(endAnimTimer);
            this.timers.push(resolveTimer);
        });
    };

    winVisibility = async (winType: string) => {
        this.wins.forEach((win, key) => {
            winType === key ? (win.visible = true) : (win.visible = false);
        });
    };

    clearNumbers = () => {
        let timer = new Timer(() => {
            this.numbers.removeChildren();
        }, 100)
        timer.initialize();
    }

    modalSleep = () => {
        return new Promise(resolve => {
            const checker = setInterval(() => {
                if (!this.isModalOpen) {
                    clearInterval(checker)
                    return resolve(true);
                }
            }, 100);
        });
    }

    clearTimers = () => {
        this.timers.forEach((timer) => {
            timer.clear();
        });
    };

    resize = () => {
        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;
        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.039;
            this.container.scale.y = window.innerHeight / 1080 - 0.039;
        }


        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.modalContainer.scale.set(1.75);
            this.numbers.scale.set(0.7)
            this.modalContainer.y = 1280;
            this.superWin.y = -50;
            this.numbers.y = 350;


        } else {
            if (window.innerWidth <= 1080) {
                this.modalContainer.scale.set(0.8)
                this.numbers.scale.set(1)
                this.modalContainer.y = 1000;
                this.superWin.y = 20;
                this.numbers.y = 365;
            } else {
                this.modalContainer.scale.set(1)
                this.numbers.scale.set(1)
                this.modalContainer.y = 900;
                this.superWin.y = 20;
                this.numbers.y = 365;
            }
        }
    }


}

export default WinModal;
