import {Howler} from 'howler';
import {SoundNames, SoundsMap} from '../constants/sounds';
import {LocalStorage} from "../../utils/localStorage";

enum BackgroundModes {
    FREEGAME = 'FREEGAME',
    BASEGAME = 'BASEGAME',
}

export class SoundController {
    controller: typeof Howler;
    bgSound: SoundNames;
    mode: BackgroundModes;
    currentState: boolean;
    bgSounds: Record<BackgroundModes, SoundNames>;
    isMusicEnable: boolean;
    isSoundEnable: boolean;

    constructor() {
        this.controller = Howler;
        this.currentState = false;
        this.mode = BackgroundModes.BASEGAME;
        this.bgSounds = {
            [BackgroundModes.BASEGAME]: SoundNames.BG_SOUND,
            [BackgroundModes.FREEGAME]: SoundNames.FREESPIN_BG_SOUND,
        };
        this.bgSound = this.bgSounds[this.mode];

        const isSoundEnable = LocalStorage.getItem('WrapperSoundsSettings')
        const isMusicEnable = LocalStorage.getItem('WrapperMusicSettings')
        this.isSoundEnable = isSoundEnable !== null ? isSoundEnable : true;
        this.isMusicEnable = isMusicEnable !== null ? isMusicEnable : true;

        this.enableSound(isSoundEnable)
        this.controller.volume(0.5);
    }

    play = (name: SoundNames, rate?: number) => {
        const forcePlayed = [
            SoundNames.BTN_UI,
            SoundNames.BONUS_WIN,
            SoundNames.WIN,
            SoundNames.FEATURE_PICKS,
            SoundNames.SCATTER_LAND_0,
            SoundNames.SCATTER_LAND_1,
            SoundNames.SCATTER_LAND_2,
            SoundNames.SPINFICATION
        ];
        const checkName = forcePlayed.filter(item => item.indexOf(name) === 0);
        if (SoundsMap[name].playing() && checkName[0] !== name) return;

        const speed = SoundsMap[name].play();
        if (rate) SoundsMap[name].rate(rate, speed);
    };

    stop = (name: SoundNames) => {
        SoundsMap[name].stop();
    };

    pause = (name: SoundNames) => {
        SoundsMap[name].pause();
    };

    changeToFsMode = (state: boolean) => {
        if (this.currentState === state) return;
        this.currentState = state;
        this.mode = state ? BackgroundModes.FREEGAME : BackgroundModes.BASEGAME;
        this.playBg();
    };

    playBg = () => {
        this.stop(this.bgSound);
        if (!this.isMusicEnable) return;
        this.bgSound = this.bgSounds[this.mode];
        this.play(this.bgSound);
    };

    pauseBg = (state: boolean) => {
        if (state) {
            this.pause(this.bgSound);
        } else if (!state && this.isMusicEnable) {
            this.play(this.bgSound);
        }
    };

    enableMusic = (state: boolean) => {
        this.isMusicEnable = state;
        this.pauseBg(!state)
    }

    enableSound = (state: boolean) => {
        this.isSoundEnable = state;
        const soundsArr = [
            SoundNames.BIG_WIN,
            SoundNames.BTN_SPIN,
            SoundNames.BTN_UI,
            SoundNames.POPUP_BONUS_START,
            SoundNames.POPUP_BONUS_TOTAL_WIN,
            SoundNames.BIG_WIN_END,
            SoundNames.BONUS_LAND,
            SoundNames.BONUS_WIN,
            SoundNames.FEATURE_PICKS,
            SoundNames.FS_TRIGGER,
            SoundNames.MULTIPLIER_SOUND,
            SoundNames.REEL_SPIN,
            SoundNames.REEL_STOP,
            SoundNames.RESPIN_TRIGGER,
            SoundNames.SPINFICATION,
            SoundNames.WILD_LOCKED,
            SoundNames.WIN,
            SoundNames.SCATTER_LAND_0,
            SoundNames.SCATTER_LAND_1,
            SoundNames.SCATTER_LAND_2,
            SoundNames.WIN_BOW,
            SoundNames.WIN_METAL,
        ]


        soundsArr.forEach(sound => {
            SoundsMap[sound].mute(!state)
        })
    }

    muteAllSounds = (state: boolean) => {
        this.controller.mute(state);
    };
}

export const Sounds = new SoundController();
