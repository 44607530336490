export function calculateDateDiff(startPoint: Date, endPoint: Date, symbol?: "=" | ">" | "<", minutes?: number) {
    const differenceInMillis = (endPoint.getTime() - startPoint.getTime()) / (1000 * 60);

    if (minutes) {
        if (symbol === "=") return differenceInMillis === minutes
        else if (symbol === ">") return differenceInMillis > minutes
        else if (symbol === "<") return differenceInMillis < minutes
    } else {
        return differenceInMillis
    }

}
