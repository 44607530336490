import { Game } from "../Game";

type CallbackParameters = {
    lossLimit?: number,
	spinsCount?: number,
	stopByAnyWin?: boolean,
	stopByFreeSpins?: boolean,
	winLimit?: number,
}

export default function setAutoParams(this: Game, params: CallbackParameters) {
    if (!this.slotMachine) return
    const autoPlayParams = {
        anyWin: params.stopByAnyWin,
        freeSpins: params.stopByFreeSpins,
        singleWin: params.winLimit,
        lossLimit: params.lossLimit,
        refreshLoss: true,
        startSpinCount: params.spinsCount,
    } as any
    this.slotMachine.requestParams = autoPlayParams;
}